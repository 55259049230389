
import { defineComponent } from "vue";

import style from "./style.module.less";

export default defineComponent({
  name: "About",
  setup() {
    return () => (
      <div class={style.container}>
        <div class={style.title}>关于我们</div>
        <div class={style.subtitle}>About Us</div>

        <div class={style.content}>
          <div>
            我是谜APP隶属于长沙希芽科技有限公司，团队源于被“谋杀之谜”的形式所吸引，
            我们继而开发了一个线上APP，让忠实爱好者能够及时组队，
            通过多元化线索和情节的设定，给予用户更多智力挑战空间和新奇玩法。于年轻人而言，这是一种新的内容体验方式，也是一种新的社交破冰方式。
          </div>

          <div>
            我是谜App是国内领先的线上谋杀推理社交app，拥有400+精品剧本。剧本杀，谋杀之谜，明侦粉的首选。我是谜App是线上剧本杀互动娱乐方式的探索者，衍生出众多新机制新玩法，比如机制本，漫画本，地图本等。
          </div>
          <div>
            我是狼人，pia戏，谁是卧底，你画我猜等多种玩法，让当代年轻人摆脱社交恐惧，轻松破冰。
            <br />
            我们以多元化的内容和丰富的玩法，让作品深入到每一位用户的内心。
          </div>
        </div>
      </div>
    );
  },
});
